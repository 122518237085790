.createKandiContainer {
    border-color: blue;
    border-width: 5px;
    border-style: solid;
    width: fit-content;
    height: fit-content;
    margin: 15px auto 15px auto;
    padding: 10px;
}

.createKandiContainer form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photoPlaceholder {
    width: 100%;
    height: 200px;
    margin: auto;
    text-align: center;
    padding: 60px 0;
    border-color: lightslategray;
    border-width: 2px;
    border-style: dashed;
    border-radius: 25px;
}

.photoPreview {
    max-width: 100%;
    max-height: 200px;
}

.buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
}